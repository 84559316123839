/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
	      
	      $(window).bind('scroll', function() {
		      if ($(window).scrollTop() > 55) {
			      $('.banner').addClass('sticky-nav');
			    }
			    else {
				    $('.banner').removeClass('sticky-nav');
				  }
				});
					
	      
				$('.why-amatis-slides').slick({
				  dots: true,
				  arrows: false,
				  autoplay: true,
				  autoplaySpeed: 4000,
				  infinite: true,
				  speed: 6000,
				  fade: true,
				  cssEase: 'linear'
				});
	      
				$('.resources-slides').slick({
				  centerMode: true,
				  centerPadding: '60px',
				  dots: true,
				  arrows: true,
				  slidesToShow: 3,
				  slidesToScroll: 1,
				  autoplay: true,
				  autoplaySpeed: 1000,
				  responsive: [
				    {
				      breakpoint: 768,
				      settings: {
				        arrows: false,
				        centerMode: true,
				        centerPadding: '40px',
				        slidesToShow: 3
				      }
				    },
				    {
				      breakpoint: 480,
				      settings: {
				        arrows: false,
				        centerMode: true,
				        centerPadding: '40px',
				        slidesToShow: 1
				      }
				    }
				  ]
				});
	      
	      AOS.init({
		      mirror: true
	      });


			  function setHeight() {
			    windowHeight = $(window).innerHeight();
			    $('.large-banner').css('min-height', windowHeight);
			  }
			  setHeight();
			  
			  $(window).resize(function() {
			    setHeight();
			  });

  

  $('#netcheck').submit(function(e){
    var URL = '/wp-content/themes/amatisnetworks/lib/postcode_hubspot_form.php';
    e.preventDefault();
    var jqxhr = $.post(URL, $(this).serialize(), function(data) {
      if(data.resp === 'OK') {
        //var ret = JSON.parse(data.lookup);
        //console.log(data.lookup);
        $('#form_wrap').fadeOut('slow', function(){
          switch(data.lookup){
            case 'YES':
              $('#yes_wrap').fadeIn('slow');
              break;
            case 'NO':
              $('#no_wrap').fadeIn('slow');
              break;
            case 'NEAR':
              $('#near_wrap').fadeIn('slow');
          }
        });
      }
    }, 'json')
      .fail(function () {
        // console.log(jqxhr);
        var strErr = 'ERROR: \n\n     Err code: '+jqxhr.responseText+'\n     Status: '+jqxhr.statusText;
        alert(strErr);
        return false;
      });
  });
  
  new fullpage('#fullpage', {
    navigation: true,
    navigationPosition: 'right',
    responsiveWidth: 992,
    lazyLoading:false,
    
    licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
    loopHorizontal: true,
    slidesNavigation: true,
    autoScrolling: true,
    slidesNavPosition: 'bottom',
    scrollOverflow: true

  });

  //### Hide the cloud sub-nav on resources
  $('#resource-filter #cloud').hide();

  $('#resource-filter .toggle.cloud-toggle').click(function(e){
    e.preventDefault();
    $(this).addClass('active');
    $('#resource-filter .toggle.connectivity-toggle').removeClass('active');
    if($('#resource-filter #cloud').is(":hidden")) {
      $('#resource-filter #connectivity').fadeOut(200, function () {
        $('#resource-filter #cloud').fadeIn(200);
      });
    }
  });

  $('#resource-filter .toggle.connectivity-toggle').click(function(e){
    e.preventDefault();
    $(this).addClass('active');
    $('#resource-filter .toggle.cloud-toggle').removeClass('active');
    if($('#resource-filter #connectivity').is(":hidden")) {
      $('#resource-filter #cloud').fadeOut(200, function () {
        $('#resource-filter #connectivity').fadeIn(200);
      });
    }
  });
  
  var $grid;

  setTimeout(function(){
    if(window.innerWidth > 992) {
      // Get an array of all element heights
      var elementHeights = $('.height-match').map(function () {
        return $(this).height();
      }).get();

      // Math.max takes a variable number of arguments
      // `apply` is equivalent to passing each height as an argument
      var maxHeight = Math.max.apply(null, elementHeights);

      // Set each height to the max height
      $('.height-match').height(maxHeight);
    }

      $grid = $('.grid').isotope({
        // options
        itemSelector: '.grid-item',
        layoutMode: 'fitRows'
      });
    }, 2000);

// flatten object by concatting values
        function concatValues( obj ) {
          var value = '';
          for ( var prop in obj ) {
            value += obj[ prop ];
          }
          return value;
        }

// store filter for each group
        var filters = {};

        $('#resource-filter').on('click', '#connectivity li, #cloud li', function() {
          var $this = $(this);
          // get group key

        //  var filterGroup = $this.parent('ul').prop('id');
          // set filter for group
        //  filters[ filterGroup ] = $this.attr('data-value');
          // combine filters
          var filterValue = $this.attr('data-value'); //concatValues( filters );

          // set filter for Isotope
          $grid.isotope({ filter: filterValue });
        });



        // Isotope filter by dropdown
			//	$('#resource_cat').change(function(){
       //   var filterValue = '.res-term-'+$(this).val();
         // console.log(filterValue);
       //   $grid.isotope({ filter: filterValue });
			//	});
  


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'infrastructure_as_a_service': {
      init: function () {
				//jQuery time
				var current_fs, next_fs, previous_fs; //fieldsets
				var left, opacity, scale; //fieldset properties which we will animate
				var animating; //flag to prevent quick multi-click glitches
				
				$(".next").click(function(){
          if (animating) {
            return false;
          }
					animating = true;
					
					current_fs = $(this).parent();
					next_fs = $(this).parent().next();
					
					//activate next step on progressbar using the index of next_fs
					$("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");
					
					//show the next fieldset
					next_fs.show(); 
					//hide the current fieldset with style
					current_fs.animate({opacity: 0}, {
						step: function(now, mx) {
							//as the opacity of current_fs reduces to 0 - stored in "now"
							//1. scale current_fs down to 80%
							scale = 1 - (1 - now) * 0.2;
							//2. bring next_fs from the right(50%)
							left = (now * 50)+"%";
							//3. increase opacity of next_fs to 1 as it moves in
							opacity = 1 - now;
							current_fs.css({
				        'transform': 'scale('+scale+')',
				        'position': 'absolute'
				      });
							next_fs.css({'left': left, 'opacity': opacity});
						}, 
						duration: 800, 
						complete: function(){
							current_fs.hide();
							animating = false;
						}, 
						//this comes from the custom easing plugin
						easing: 'easeInOutBack'
					});
				});
				
				$(".previous").click(function(){
          if (animating) {
            return false;
          }
					animating = true;
					
					current_fs = $(this).parent();
					previous_fs = $(this).parent().prev();
					
					//de-activate current step on progressbar
					$("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");
					
					//show the previous fieldset
					previous_fs.show(); 
					//hide the current fieldset with style
					current_fs.animate({opacity: 0}, {
						step: function(now, mx) {
							//as the opacity of current_fs reduces to 0 - stored in "now"
							//1. scale previous_fs from 80% to 100%
							scale = 0.8 + (1 - now) * 0.2;
							//2. take current_fs to the right(50%) - from 0%
							left = ((1-now) * 50)+"%";
							//3. increase opacity of previous_fs to 1 as it moves in
							opacity = 1 - now;
							current_fs.css({'left': left});
							previous_fs.css({'transform': 'scale('+scale+')', 'opacity': opacity});
						}, 
						duration: 800, 
						complete: function(){
							current_fs.hide();
							animating = false;
						}, 
						//this comes from the custom easing plugin
						easing: 'easeInOutBack'
					});
				});
				
        $(".submit").click(function () {
          return false;
        });

        var arrPrices = {
          vCPU: {
            2: 545.2,
            4: 1090.4,
            8: 2180.8,
            12: 3271.2,
            16: 4361.6,
            24: 6542.4,
            32: 8723.2,
            48: 13084.8
          },
          vRAM: {
            8: 872.16,
            16: 1744.32,
            32: 3488.64,
            64: 6977.28,
            128: 13954.56,
            256: 27909.12,
            512: 55818.24,
            1024: 111636.48,
            2048: 223272.96
          },
          vDISK: {
            '0.5': 574.58,
            1: 1149.15,
            2: 2298.3,
            3: 3447.46,
            4: 4596.61,
            5: 5745.76,
            10: 11491.52,
            15: 19535.58,
            20: 22983.04
          }
        };
        

        //######  RANGE SLIDERS #####
        var cpuSlider = $("#vCPU").bootstrapSlider({
          ticks: [2, 4, 8, 12, 16, 24, 32, 48],
          ticks_positions: [0, 14, 28, 43, 57, 71, 85, 100],
          ticks_labels: ['2', '4', '8', '12', '16', '24', '32', '48'],
          ticks_snap_bounds: 30,
          value: 2
        });

        // $("#vCPU").on('change', function (value) {
        //   calculateTotal();
        // })

        //######  RANGE SLIDERS #####
        var vramSlider = $("#vRAM").bootstrapSlider({
          ticks: [8, 16, 32, 64, 128, 256, 512, 1024, 2048],
          ticks_positions: [0, 12.5, 25, 37.5, 50, 62.5, 75, 87.5, 100],
          ticks_labels: ['8', '16', '32', '64', '128', '256', '512', '1024', '2048'],
          ticks_snap_bounds: 1050,
          value: 8
        });

        // $("#vRAM").on('change', function (value) {
        //   calculateTotal();
        // })

         //######  RANGE SLIDERS #####
         var vdiskSlider = $("#vDISK").bootstrapSlider({
           ticks: [0.5, 1, 2, 3, 4, 5, 10, 15, 20],
           ticks_positions: [0, 12.5, 25, 37.5, 50, 62.5, 75, 87.5, 100],
           ticks_labels: ['500GB', '1TB', '2TB', '3TB', '4TB', '5TB', '10TB', '15TB', '20TB'],
           ticks_snap_bounds: 5,
           step: 0.5,
           value: 0.5
         });
        
        //###############################
        var calculateTotal = function () {
          var total = 0;
          var sliderValues = {
            vCPU: cpuSlider.bootstrapSlider('getValue'),
            vRAM: vramSlider.bootstrapSlider('getValue'),
            vDISK: vdiskSlider.bootstrapSlider('getValue')
          };

          total = arrPrices.vCPU[sliderValues.vCPU] + arrPrices.vRAM[sliderValues.vRAM] + arrPrices.vDISK[sliderValues.vDISK];

          if ($('#winLic').is(':checked')) {
            total += (sliderValues.vCPU / 2) * 351.6;
          }
          total = Math.round(total * 100) / 100;

          $('#total').text(total.toLocaleString(undefined, {
            minimumFractionDigits: 2
          }));
        };
        
        
        $("#vCPU, #vRAM, #vDISK").on('change', function (value) {
          calculateTotal();
        });

        $('#winLic').change(function () {
          calculateTotal();
        });

        calculateTotal();
        
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
